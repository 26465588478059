export const normalizeDate = (date) => {
  if (!date) return "Invalid date"; 

  const newDate = new Date(date * 1000);
  if (isNaN(newDate.getTime())) return "Invalid date"; 

  let day = newDate.getDate().toString().padStart(2, "0");
  let month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear();

  return `${day}.${month}.${year}`;
};

export const safeBtoa = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

export const useNormalizeCtfUrl = (url) => {
  if (!url || typeof url !== "string") {
    return "/img/photo-plug.svg"; 
  }

  const config = useRuntimeConfig();
  if (config?.public?.appUrl === "http://localhost:3000") {
    return url;
  }

  try {
    const urlToBtoa = safeBtoa("https:" + url);
    return `${config.public.appUrl}/static/assets/${urlToBtoa}`;
  } catch (e) {
    console.error("Error encoding URL to Base64:", e);
    return "/img/photo-plug.svg"; 
  }
};
